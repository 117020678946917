import '/assets/styles/pages/listing.scss';
import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// Functions
import isExact from '@wearetla/tla-essentials-tools/functions/is-exact';

// Partials
import { Input } from '/views/partials/forms'
import Loader from '/views/partials/loader'
import Pagination from '/views/partials/pagination'
import Eventbox from '/views/partials/eventbox';
import EmptyMessage from '/views/partials/empty-message'

// Context
import { useNavigator } from '@wearetla/tla-essentials-tools/utilities/navigator'
import { useLocations } from '/utilities/locations';
import { ListingProvider, useListing } from '@wearetla/tla-essentials-tools/utilities/listing';
import { useHead } from '@wearetla/tla-essentials-tools/utilities/head';
import { useGlobalEvents } from '@wearetla/tla-essentials-tools/utilities/global-events';
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';

// Hooks
import useBranches from '/hooks/branches';

// Data
import listingFilters from '/data/listing-filters/search';
// import sortingOptions from '/data/sorting-options';

// Services
import eventServices from '/services/event';

const defaultQuery = {
	page: '1',
	order: 'asc',
	limit: '12',
	branch: '',
	location: '',
	term: '',
}

const getEvents = (query) => {
	return eventServices.getEvents(query);
}

const parseHead = (searchText) => {
	return {
		title: `Arama Sonuçları: "${searchText}"`,
		description: `"${searchText}" kelimesine dair etkinlik sonuçları.`,
	}
}

const SearchProvider = ({match, ...props}) => {
	const externalParams = useMemo(() => ({
		term: match?.params?.term,
	}), [match?.params.term])

	return (
		<ListingProvider
			externalParams={externalParams}
			availableFilters={listingFilters}
			queryString={match?.params?.query}
			defaultQuery={defaultQuery}>
			<Search {...props} />
		</ListingProvider>
	)
}

SearchProvider.propTypes = {
	match: PropTypes.object,
}

const Search = ({ pageProps }) => {
	const { triggerEvent } = useGlobalEvents();
	const { setNotFound } = useGlobalState();
	const branches = useBranches();
	const { locationOptions } = useLocations();
	const { query, inputQuery, outputData, setFilter, paginate } = useListing();
	const { redirect } = useNavigator();
	const { setHead } = useHead();

	const [{events, pagination, fetchedQuery}, setData] = useState(pageProps?.productData ?? {events: null, fetchedQuery: null, pagination: null});

	// const selectedSortOption = useMemo(() => {
	// 	return sortingOptions.find(o => o.value === inputQuery.order)
	// }, [inputQuery])

	const branchOptions = useMemo(() => ([
		{
			label: 'Tümü',
			value: false,
		},
		...(branches ?? []).map(branch => ({
			label: branch.title,
			value: branch.slug,
		}))
	]), [branches])

	const selectedBranch = useMemo(() => branchOptions.find(f => f.value === (query.branch || false)) ?? null, [branchOptions, query])

	const selectedLocation = useMemo(() => {
		return locationOptions.find(l => {
			return (l.value || '') === (inputQuery.location || '');
		});
	}, [inputQuery, locationOptions])

	useEffect(() => {
		setHead(parseHead(inputQuery.term));
	}, [inputQuery])

	useEffect(() => {
		if(inputQuery.term && !isExact(fetchedQuery, inputQuery)) {
			setData({ events: null, pagination: null, fetchedQuery: inputQuery });
			getEvents(inputQuery).then(productData => {
				if(inputQuery?.term) {
					triggerEvent('makeSearch', { term: inputQuery.term })
				}
				setData({ ...productData, fetchedQuery: inputQuery});
			}).catch(() => {
				setNotFound();
			})
		}
	}, [inputQuery, fetchedQuery])

	useEffect(() => {
		if(inputQuery?.term === outputData?.externalParams?.term) {
			const params = {
				term: outputData.externalParams.term,
			};
			
			if(outputData.queryString && outputData.queryString.length) {
				params.query = outputData.queryString;
			}

			redirect('search', params)
		}
	}, [outputData, inputQuery])

	return (
		<div className="listing-wrap wrapper">
			<h1 className="listing-title">Arama Sonuçları: "{inputQuery.term}"</h1>

			<nav className="listing-filters">
			<div className="filters-left">
					{/* <div className="filters-locations cities">
						<strong className="locations-title">Nerede?</strong>
						{locationOptions.map((location) => (
							<Link
								href={location?.slug ? 'eventsWithLocation' : 'events'}
								params={location?.slug ? { location: location.slug } : undefined}
								key={location?.id ?? 'empty'}
								className={classNames('locations-item', { selected: (location.city ?? '') === inputQuery?.location })}>
								<span className="item-check"></span>
								<span>{location.shortTitle}</span>
							</Link>
						))}
					</div> */}

					<div className="filters-city">
						<Input
							label="Şehir"
							z={2}
							type="select"
							onChange={(c) => {
								setFilter('location', c.value);
								setFilter('branch', '');
							}}
							value={selectedLocation}
							options={locationOptions} />
					</div>
					<div className="filters-branch">
						<Input
							label="Şube"
							z={1}
							type="select"
							onChange={(c) => {
								setFilter('branch', c.value);
							}}
							value={selectedBranch}
							options={branchOptions} />
					</div>
				</div>

				{/* <Input
					className="filters-sorting"
					type="select"
					value={selectedSortOption}
					onChange={(option) => {
						setFilter('order', option.value);
					}}
					options={sortingOptions} /> */}
			</nav>

			<div className="listing-results loader-container">
				<Loader loading={events === null} />
				{events && events.length > 0 &&
					<>
						<ul className="results-events">
							{events.map((event) => (
								<li className="listing-event" key={event.calendar_id}>
									<Eventbox event={event} />
								</li>
							))}
						</ul>
						<Pagination
							className="results-pagination"
							data={pagination}
							onPaginate={paginate} />
					</>
				}
				{events?.length === 0 &&
					<EmptyMessage>Etkinlik Bulunamadı.</EmptyMessage>
				}
			</div>
		</div>
	)
}

Search.propTypes = {
	pageProps: PropTypes.object
}

export default SearchProvider