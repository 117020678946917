const defaultFilter = {
	isFilter: true,
	filterProcessor: 'default', // filtre verisinin ne şekilde işlenip filtre modalına aktarılacağını belirler
	title: 'Filtre', // filtre başlığı
	valueKey: 'id', // filtre verisinin value alanının hangi field'dan geleceğini belirler
	labelKey: 'name', // filtre verisinin label (metin) alanının hangi field'dan geleceğini belirler
	valueFormat: 'string', // query string'den gelen value alanının formatı.
	affectedFields: [], // query'nin filtre içerisinde etkileşim kurduğu ek fieldlar (temizleme işlemleri için kullanılır)
}

const eventListingFilters = {
	// Unlisted / Custom
	limit: {
		...defaultFilter,
		filterProcessor: false,
	},
	term: {
		...defaultFilter,
		filterProcessor: false,
	},
	location: {
		...defaultFilter,
		filterProcessor: false,
	},
	branch: {
		...defaultFilter,
		filterProcessor: false,
	},
	page: {
		...defaultFilter,
		filterProcessor: false,
	},
	order: {
		...defaultFilter,
		filterProcessor: false,
	},
}

export default eventListingFilters